import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61')
];

export const server_loads = [0,2,6,3,7,9,10,11];

export const dictionary = {
		"/(feeds)": [~22,[4]],
		"/account": [~24,[5]],
		"/account/notifications": [~25,[5]],
		"/(content)/articles/[id]": [12,[2]],
		"/auth/apply": [~26,[6]],
		"/auth/login": [27,[6]],
		"/auth/login/otp": [28,[6]],
		"/(content)/buy-sell": [13,[2,3]],
		"/(content)/buy-sell/[id]": [14,[2,3]],
		"/(content)/buy-sell/[id]/contact": [~15,[2,3]],
		"/conversations": [29],
		"/(content)/deals": [~16,[2]],
		"/(content)/deals/[id]": [~17,[2]],
		"/editor/[[id]]/article": [~31,[7]],
		"/editor/[[id]]/buy-sell": [~32,[7]],
		"/editor/[[id]]/deal": [~33,[7]],
		"/editor/[[id]]/event": [~34,[7]],
		"/editor/[[id]]/poll": [~35,[7]],
		"/editor/[[id]]/post": [~36,[7]],
		"/editor/[[id]]/responses/[[response_id]]": [37,[7]],
		"/editor/[[id]]": [30,[7]],
		"/(content)/events": [~18,[2]],
		"/(content)/events/[id]": [~19,[2]],
		"/invites/[id]": [~38,[],[8]],
		"/lang": [39],
		"/onesignal": [40],
		"/(content)/polls/[id]": [20,[2]],
		"/(content)/posts/[id]": [21,[2]],
		"/report/[id]": [~41],
		"/(feeds)/rooms/[slug_id]": [~23,[4]],
		"/search": [42],
		"/settings": [~43,[9]],
		"/settings/admins": [~44,[9]],
		"/settings/admins/invite": [45,[9]],
		"/settings/applications": [~46,[9]],
		"/settings/applications/[id]": [~47,[9]],
		"/settings/authentication": [~48,[9]],
		"/settings/content-types": [~49,[9]],
		"/settings/design": [~50,[9]],
		"/settings/domain": [~51,[9]],
		"/settings/members": [~52,[9]],
		"/settings/members/invite": [~55,[9]],
		"/settings/members/[id]": [53,[9,10]],
		"/settings/members/[id]/suspend": [54,[9,10]],
		"/settings/reports": [56,[9]],
		"/settings/reports/[id]": [~57,[9]],
		"/settings/rooms": [~58,[9,11]],
		"/settings/rooms/categories": [60,[9,11]],
		"/settings/rooms/categories/[new_or_id=new_or_id]": [~61,[9,11]],
		"/settings/rooms/[new_or_id=new_or_id]": [~59,[9,11]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';